@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700&family=Montserrat:wght@300;400;700&display=swap');

.text-center{
  text-align: center;
}
::-webkit-scrollbar {
  width: 5px;
 
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #b1afaf; 
 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8f8f8f; 
}
.form {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
  }
  
  .input {
    margin-bottom: 10px;
    padding: 5px;
    display: flex;
    float: right;
  }
  
  .error {
    color: red;
  }
  .button {
      padding: 10px;
      background-color: blue;
      color: white;
      border: none;
  }
  .lable-style {
    margin-bottom: '5px';
    margin-right: '5px';
    font-weight: 'bold';
}
.hide {
  display: none;
}

.signUpArea{
  display: flex;
  font-family: 'Inter', sans-serif;
}
.signUpContent{
  color:#fff;
  background-color: #7435FF;
  background-image: url("../image/bgSignUp.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.signUpForm, .signUpContent{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
/* .signUpForm{padding: 50px 0 0;} */
.signUpForm.successPart{padding: 100px 0;}
.signUpContentSection{
  margin: auto;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #031639;

  .packageBox{
    background-color: #fff;
    border-radius: 16px;
    padding: 4.5rem 3.5rem;
    width: -webkit-fill-available;
    max-height: 60vh;
  
    .selectArea{
      color: #58596C;
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 0.5rem;
    }
    .packagePart{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .packageArea{
        display: flex;
        flex-direction: column;
  
        h4{
          margin: 0;
          color: #031639;
          font-size: 1rem;
          font-weight: 700;
          line-height: 20px;
        }
        .subHead{
          color: #071028;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
        .member{
          color: #4F5566;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
      }
      .changeBtn{
        color: #0075FF;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-decoration: none;
      }
    }
    .pricePart{
      .starts{
        color: #4F5566;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin: 0.5rem 0;
      }
      .priceArea{
        display: flex;
        align-items: center;
        gap: 0.5rem;

        h3{
          margin: 0;
          color: #062255;
          font-size: 28px;
          line-height: 32px;
          font-weight: 700;
        }
        .priceMax{
          color: #4F5566;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          text-decoration: line-through;
        }
        .priceDiscount{
          color: #1CB961;
          font-size: 10px;
          line-height: 16px;
          font-weight: 700;
        }
      }
      .users{
        color: #4F5566;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin: 0.2rem 0 0;
      }
      .billed{
        color: #4F5566;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin: 0;
      }
    }
    .planModule{
      font-size: 12px ;
      padding-left: 28px;
    }
    .includePart{
      margin: 1rem 0 0;
      height: 280px;
      max-height: 350px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      
      .includeHeading{

        h5{
          margin: 0;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: #031639;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
        }
      }
      .includeText{
        ul{
          list-style: none;
          padding: 0;
          margin: 0;

          li{
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin: 0.8rem 0 0;
            color: #58596C;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
.signUpForm.successPart h2{margin:30px 0;}
.signUpForm h2{
  font-size: 24px;
  color:#031639;
  font-weight: 700;
  margin:10px 0 5px;
}
.signUpForm p{
  font-size: 13px;
  color:#58596C;
  font-weight: 500;
  margin:0;
}
.signUpForm p a{color:#58596C;text-decoration: none;}
.signUpForm p button{
  background: transparent;
  color:#58596C;
  border: none;
  padding: 0;
  font-weight: 700;
  cursor: pointer;
}
.signUpForm.successPart p{margin-bottom: 20px; font-size: 13px;font-weight: 400;}
.signUpForm .formArea{
  margin: 20px 0 0;
}
.signUpForm .formArea .inputField{
  margin: 0 0 10px;
}
.signUpForm .formArea .inputField input{
  padding: 7px 10px;
}
.signUpForm .formArea .inputField .ant-btn{
  width: 100%;
  background-color: #0075FF;
  border: 1px solid #4096ff;
  color:#fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  height: 38px;
}
.signUpForm.successPart .inputField .antBtn{
  width: 100%;
  background-color: #0075FF;
  color:#fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  height: 38px;
  margin-top: 30px;
}
.signUpForm.successPart .inputField .antBtnBorder{
  width: 100%;
  border: 1px solid black;
  color:#000;
  background: transparent;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  height: 38px;
  margin-top: 30px;
}
.signUpForm.successPart .inputField .antBtnBorder:hover{
 
  border: 1px solid #0075FF;
  
}
.signUpForm .formArea .inputFieldText{
  font-size: 12px;
  text-align: center;
}
.signUpForm .formArea .inputFieldText .link{
  color:#0075FF;
}

.text-red .errorMsg{color:red; font-size: 12px; padding: 0 0 0 10px;}
.signUpForm .formArea .inputFieldText .textButton{
  background: transparent;
  border: none;
  color:#0075FF;
  padding: 0;
  font-size: 12px;
  cursor: pointer;
}
.signUpForm .formArea .inputFieldText a{color:#0075FF;text-decoration: none;font-size: 12px;}

.signUpArea .signUpContent h2{
  font-size: 36px;
  margin: 20px 0;
}
.whiteBoxArea{
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: 250px;
}
.whiteBox {
  background: #fff;
  border-radius: 1.25rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  opacity: 0;
  height: auto;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  position: absolute; 
  top: 0; 
  left: 0; 
  pointer-events: none;
}

.whiteBox.active {
  opacity: 1;
  pointer-events: auto;
}
.profileArea, .commentArea, .remarkArea{
  display: flex;
  align-items: center;
}
.profileArea{
  gap: 1.25rem;
}
.profileDetails{
  display: flex;
  flex-direction: column;
  gap: 0.313rem;
}
.profileArea h3, .profileArea h6{
  margin: 0;
}
.profileArea h3{
  font-weight: bold;
  font-size: 1rem;
}
.profileArea h6{
  color: #58596C;
  font-weight: 500;
  font-size: 0.875rem;
}
.commentArea{
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.remarkArea{
  gap:0.375rem;
}
.slideButtonArea{
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap:0.5rem;
}
.slideButton{
  background: #F0F0F0;
  width: 50px;
  height: 3px;
  cursor: pointer;
}
.slideButton.active{
  background: #FFB020;
}
.countrySecion{
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9d9d9;
  border-radius:6px;
}
.countrySecionError{
  display: flex;
  align-items: center;
}
.countryCode{width: 30%;}
.countryCode.input-text-error{border-radius: 6px;}
.countryNumber{width: 70%;}
.signUpForm .formArea .countrySecion input, .signUpForm .formArea .countrySecion .ant-select-selector{
  border: none;
  outline: none;
}
.signUpForm .formArea .countrySecion input.input-text-error{
  border: 1px solid red;
}
.signUpForm .formArea .countrySecion .countryCode .ant-select{
  height: 40px;
}
.signUpForm .formArea .inputField .ant-select{height: 44px;}
.signUpForm .formArea .countrySecion .countryCode.input-text-error .ant-select{
  height: 42px;
}
.signUpForm .formArea .dataLocate{
  padding: 0 10px;
  font-size: 13px;
}
@media screen and (min-width:1401px) {
  .signUpForm .formArea .inputField input{
    padding: 10px;
  }
  .signUpForm .formArea .inputField .ant-btn{
    height: 44px;
  }
  .signUpForm .formArea .inputField{
    margin: 0 0 15px 0;
  }
  /* .signUpContent{
    height :calc(100vh - 60px);
  } */
}
@media screen and (min-width:1024px) and (max-width:1400px) {
  .signUpContent img{
    max-width: 70%;
  }
  .signUpArea .signUpContent h2{font-size: 24px;}
  .signUpContent ul.listItem li, .signUpContent ul.noIconSection li{font-size: 12px;}
  .signUpForm .formArea .inputField .ant-select{height: 38px;}
}
@media screen and (max-width:767px) {
  .signUpContent{display: none;}
  .signUpForm{
    width:90%;
    margin: 0 5%;
  }
}
.input-text-error {
  border: 1px solid red;

  &.ant-select{
    width: 100%;
  }

  &.ant-input{
    height: 44px;
  }

  &:hover {
    border: 1px solid red;
  }

  &:focus {
    border: 1px solid red;
  }
}

.forgetPasswordPart{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ECF5FF;
  height: 100vh;
}

.forgetPasswordForm {
  background-color: #fff;
  background-size: cover;
  max-width: 400px;
  min-height: 400px;
  padding: 40px 30px;
  width:100%;
  box-shadow: 0 0 21px 2px rgba(12,9,27,.07);
}
.forgetPasswordForm .inputField {
  margin: 0 0 15px 0;
}
.forgetPasswordForm .inputField input {
  padding: 10px;
}
.forgetPasswordForm .inputField .ant-btn{
  width: 100%;
  background-color: #0075FF;
  border: 1px solid #4096ff;
  color:#fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  height: 44px;
}
.forgetPasswordForm .inputField .ant-btn:hover{
  color:#fff;
}
.forgetPasswordForm .inputField a{
  color: #0075FF;
  text-decoration:none;
}
.forgetPasswordForm .inputField .link{
  color: #0075FF;
  text-decoration:none;
}
.forgetPasswordPart h2{
  font-size: 24px;
  color:#031639;
  font-weight: 700;
}
.forgetPasswordPart p{
  font-size: 13px;
  color:#58596C;
  font-weight: 500;
}
.cursor-pointer {
  cursor: pointer;
}
.policies{
  overflow-y: auto;
   max-height: 480px;
}
.pricing{
  overflow-y: auto;
   max-height: 480px;
}
.ant-modal{top:40px;}
.ant-modal-body{
 
  
}
.ant-modal-close{
  top:5px;
  inset-inline-end:5px;
}
/* .ant-modal-footer{display: none;} */
@media screen and (min-width:1900px) {
  .ant-modal-body{
    max-height: 680px;
   
  }
}